<template>
  <div class="myInfo">
      <div class="content">
            <div class="topTitle flex">
                <van-image  width="65"  height="65" radius="10" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                <div class="info">
                    <div class="userName">黑神之神</div>
                    <div class="signature">健康快乐每一天</div>
                </div>
            </div>
      </div>
      <vaeTitle title="我的工具" class="title"></vaeTitle>
      <div class="content">
            <div class="topTitle">
                <div class="item flex" @click="toMyNews">
                    <div class="itemL">
                        <img src="@/assets/tabbar/message.svg" alt="">
                        <span class="pl10">我的消息</span>
                    </div>
                    <van-icon name="arrow" />
                </div>
                <div class="van-hairline--top"></div>
                <div class="item flex" @click="physician">
                    <div class="itemL">
                        <img src="@/assets/tabbar/zixun.svg" alt="">
                        <span class="pl10">客服咨询</span>
                    </div>
                    <van-icon name="arrow" />
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import vaeTitle from '@/components/title.vue'
import {getLocal} from '../../utils/local'
export default {
    components:{
        vaeTitle
    },
    name:'myInfo',
    data () {
        return {}
    },
    created () {
    },
    mounted () {
    },
    methods:{
        toMyNews () {
            this.$router.push({path:'/myNews'})
        },
        physician () {
            window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg2ODY0OTMxOA==&scene=124#wechat_redirect'
        },
    }
}
</script>

<style lang="less" scoped>
    .myInfo {
        // margin-top: 15px;
        .content {
            
            padding:  15px;
            .topTitle {
                padding: 14px 18px;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
                .info {
                    padding-left: 10px;
                    flex: 1;
                    .userName {
                        color: #333333;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .signature {
                        font-size: 14px;
                        color: #999;
                    }
                }
                .item {
                    color: #333333;
                    font-size: 16px;
                    padding: 14px 0;
                    .itemL {
                        flex: 1;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        .flex {
            display: flex;
            align-items: center;
        }
        .pl10 {
            padding-left: 10px;
        }
    }
</style>